import { render, staticRenderFns } from "./CreateUser.vue?vue&type=template&id=5b956813&scoped=true"
import script from "./CreateUser.vue?vue&type=script&lang=js"
export * from "./CreateUser.vue?vue&type=script&lang=js"
import style0 from "./CreateUser.vue?vue&type=style&index=0&id=5b956813&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b956813",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QCard,QForm,QInput,QSelect,QBtn});
