<template>
  <q-page>
    <div class="page-header">
      <h4>{{ $t('user_registration') }}</h4>
    </div>
    <q-card flat bordered class="q-ma-lg">
      <q-form class="q-pa-xl self-center" @submit.prevent="createUser">
        <label for="name">{{ $t('name') }}</label>
        <q-input
          v-model="name"
          class="q-mb-md"
          name="name"
          :label="$t('enter_name')"
          outlined
          :rules="[(val) => !!val || $t('name_required')]"
        />

        <label for="email">E-mail</label>
        <q-input
          v-model="mail"
          class="q-mb-md"
          name="email"
          :label="$t('enter_email')"
          outlined
          :rules="[(val) => !!val || $t('email_required')]"
        />

        <label for="profile">{{ $t('profile') }}</label>
        <q-select
          v-model="profile"
          class="q-mb-md"
          name="profile"
          :options="profileOptions"
          :label="$t('choose_profile')"
          outlined
          :rules="[(val) => !!val || $t('profile_required')]"
        />

        <label for="role">{{ $t('occupation') }}</label>
        <q-select
          v-model="role"
          class="q-mb-md"
          name="role"
          :options="roleOptions"
          :label="$t('choose_occupation')"
          outlined
          :rules="[(val) => !!val || $t('occupation_required')]"
        />

        <q-btn unelevated type="sumbit" color="green-5" :label="$t('register_user')" />
      </q-form>
    </q-card>
  </q-page>
</template>

<script>
export default {
  data() {
    return {
      name: null,
      mail: null,
      profile: null,
      role: null,
      profileOptions: [
        {
          label: 'Operacional',
          value: 1
        },
        {
          label: 'Comercial',
          value: 2
        },
        {
          label: 'Tecnologia',
          value: 3
        },
        {
          label: 'Financeiro',
          value: 4
        },
        {
          label: 'Redes',
          value: 8
        },
        {
          label: 'Integrações',
          value: 9
        }
      ],
      roleOptions: [
        {
          label: 'Administrador',
          value: 1
        },
        {
          label: 'Operacional',
          value: 2
        },
        {
          label: 'Comercial',
          value: 3
        },
        {
          label: 'Tecnologia',
          value: 4
        }
      ]
    };
  },

  methods: {
    async createUser() {
      this.onLoading(true);

      let params = new FormData();
      params.append('name', this.name);
      params.append('email', this.mail);
      params.append('profile_id', this.profile.value);
      params.append('role_id', this.role.value);
      params.append('multi_factor_auth', 0);

      try {
        const { status } = await this.$http.post('/create/manager', params);
        if (status === 200) {
          this.successNotify('Afiliado cadastrado com sucesso!');
        }
      } catch (error) {
        this.errorNotify(error.message);
      } finally {
        this.onLoading(false);
      }
    }
  }
};
</script>

<style scoped>
form {
  max-width: 900px;
}
</style>
